@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


/******************************
	Globals
******************************/

/******************************
	Base structure
******************************/

html {
	font-family:system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

body {
//  font-family: 'Roboto', sans-serif;
  border-top: 8px solid #3E75ED;
  font-size:1rem;
  line-height: 1.625;
  color:#000;
	text-align:left;
}

section:first-of-type {	padding: 3rem 1.5rem 1.5rem; }
section:not(:first-of-type) {	padding: 0 1.5rem 1rem; }
section .container { padding-bottom: 1.5rem; border-bottom: solid #f4f5f7 2px; }
section:last-of-type {	padding: 1.5rem 1rem; }
section:last-of-type .container {	border-bottom: none; }


time {
	text-transform: uppercase;
	font-size: .75rem;
	font-weight: 700;
	color: #6b7280;
	
}

.is-slim { max-width: 50rem; }

.content h1 { font-size: 1.875rem; 	font-weight: 800; line-height: 2.5; }
.content h2 { font-size: 1.5rem; 		font-weight: 700; line-height: 1.25; }
.content h3 { font-size: 1.35rem;		font-weight: 700; line-height: 1.25; }
.content h4 { font-size: 1.2rem;		font-weight: 700; line-height: 1.25; }
.content p { font-size: 1rem; }

.content p:not(:last-child) {	margin-bottom: 2rem; }

a { color: #3E75ED; }

ul { margin-bottom: 2em; }

/******************************
	Navbar
******************************/

.navbar-brand,
.navbar-burger { height: 5rem; }

.active {
	padding-top: 0;
	padding-bottom: 0;
	background-color: #3E75ED;
}
.active a {	color: #fff; }
.active:hover { background-color: #1d366e; }
.active a:hover { 
	background-color: #1d366e;
	color: #fff; 
}

a.navbar-item {
	color: #3E75ED;
	font-weight:600;
}

a.navbar-item:hover {
	color: #4a4a4a;
	text-decoration: underline;
}

.site-title {
	font-size: 1.6rem;
	font-weight: 700;
	letter-spacing: -2px;
	color:#000;
}

.site-title:hover {	color:#000; }

.tagline { padding: 8px 0 0px; }

.content blockquote:not(:last-child) {
	margin-bottom: 2rem;
}

/******************************
	Email
******************************/

.email-signup {
  background: linear-gradient(45deg, #fafafa 40%, #eeeeee 60%);
}

.button.is-info.is-outlined {
	border-color: #3E75ED;
	color: #3E75ED;
}

.button.is-info.is-outlined:hover {
	border-color: #3E75ED;
	background-color: #3E75ED;
}

/******************************
	HOMEPAGE aka BLOG INDEX PAGE
******************************/

.title {
	font-size: 1.5rem;
	font-weight:	800;
	line-height: 1.125;
	color: #000;
	letter-spacing: -.025em;
}

.title:hover {
	text-decoration:underline;
}

.title-date {
	margin-top:1rem;
}

.excerpt {
  margin: 1rem 0;
}

.fa-calendar-day {
	color: #3E75ED;
}

.read-more {
	font-weight: 500;
	text-decoration: underline;
}

/******************************
	INDIVIDUAL POST PAGE
******************************/

.content h1:not(:first-child) { 
	letter-spacing: -.025em;
	margin: .5rem 0;
	line-height: 2.5rem;
}

.other-posts {
	font-size: .875rem;
	font-weight: 900;
	letter-spacing: .025em;
	text-transform: uppercase;
}

.further-reading {
	border-bottom: solid #d2d6dc;	
	padding: 1.5rem 0;
}

.further-reading h3 {
	color: #3E75ED;
}

/******************************
	Footer
******************************/


/******************************
	BACK TO TOP
******************************/

#myBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 150px; /* Place the button at the bottom of the page */
  right: 0px; /* Place the button along the right side */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  color: #3E75ED; /* Text color */
	cursor: pointer; /* Add a mouse pointer on hover */
	display: block;
	background: rgba(238, 238, 238, 0.65);
	padding: 8px;
	border-radius: 8px 0 0 8px;
	padding-bottom: 4px;
}

/* Extra small devices (portrait phones, less than 768px)
No media query for `mobile` since this is the default
-------------------------------------------------- */

/* Tablets (tablets, 769px and up) */
@media (min-width: 768px) { 
	.site-title {
		font-size: 1.8rem;
		font-weight: 700;
	}

	.title-container {
		margin-bottom: 2rem;
	}

	.title { font-size: 1.875rem;	}

	section .container {
		max-width: 36rem;
	}

	.content h1 {
		font-size: 3rem;
		line-height: 1rem;
	}

	.content figure img {
		border-radius: .25rem;
		margin-top: 2.5rem;
		margin-bottom: 1.5rem;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.2), 0 6px 28px 0 rgba(0, 0, 0, 0.19);
	}
	
	.content blockquote p { font-size: 1.3rem;	}
	
}

/* Desktops (desktops, 1024px and up) */
@media (min-width: 1024px) { 
	.content figure img {
		max-width: unset;
		width: 150%;
		margin-left: -25%;
	}
	blockquote {
		max-width: unset;
		width: 120%;
		margin-left: -10%;
	}

	.content blockquote p {
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 1.4;
	}
	
}

/* Widescreen devices (large desktops, 1216px and up) */
@media (min-width: 1216px) {  }

/* Full HD devices (extra large desktops, 1408px and up) */
@media (min-width: 1408px) {  }